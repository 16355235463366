<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Sales Log</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Sales Log</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="overlay" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-header">
              <h3 class="card-title">Detail</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'SalesLogList'}">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td><b>ID</b></td>
                    <td>{{ value.id }}</td>
                  </tr>
                  <tr>
                    <td><b>Client Reference-Id</b></td>
                    <td>{{ value.client_ref_id }}</td>
                  </tr>
                  <tr>
                    <td><b>Account ID</b></td>
                    <td>{{ value.account_id }}</td>
                  </tr>
                  <tr>
                    <td><b>amount</b></td>
                    <td>{{ value.amount }}</td>
                  </tr>
                  <tr>
                    <td><b>Commission amount</b></td>
                    <td>{{ value.commission_amount }}</td>
                  </tr>
                  <tr>
                    <td><b>Accounting Trx Ref-Id</b></td>
                    <td>{{ value.acct_trx_ref_id }}</td>
                  </tr>
                  <tr>
                    <td><b>Accounting Trx Response Code</b></td>
                    <td>{{ value.acct_trx_resp_code }}</td>
                  </tr>

                  <tr>
                    <td><b>Status</b></td>
                    <td>{{ value.status }}</td>
                  </tr>

                  <tr>
                    <td><b>Product-Id</b></td>
                    <td>{{ value.product_id }}</td>
                  </tr>

                  <tr>
                    <td><b>Product Quantity</b></td>
                    <td>{{ value.product_quantity }}</td>
                  </tr>

                  <tr>
                    <td><b>User-Id</b></td>
                    <td>{{ value.user_id }}</td>
                  </tr>

                  <tr>
                    <td><b>Customer-Id</b></td>
                    <td>{{ value.customer_id }}</td>
                  </tr>

                  <tr>
                    <td><b>User Role-Id</b></td>
                    <td>{{ value.user_role_id }}</td>
                  </tr>

                  <tr>
                    <td><b>Customer Role-Id</b></td>
                    <td>{{ value.customer_role_id }}</td>
                  </tr>

                  <tr>
                    <td><b>User Organization-Id</b></td>
                    <td>{{ value.user_org_id }}</td>
                  </tr>

                  <tr>
                    <td><b>Customer Organization-Id</b></td>
                    <td>{{ value.customer_org_id }}</td>
                  </tr>

                  <tr>
                    <td><b>App ID</b></td>
                    <td>{{ value.app_id }}</td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>
<script>
import { CBSCommissionApi } from '@/services/api';
export default {
  name: 'SalesLogShow',
  data: () => ({
    value: {},
    pre: {},
    is_loading: false
  }),
  mounted: function () {
    this.initialize()
  },
  methods: {
    getDetail: function (id) {
      CBSCommissionApi.showSalesLog(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params
      await this.getDetail(this.params.id)
      this.is_loading = false
    }
  }
}
</script>
